import React from "react";
import EvetnInfoBG from "../../assets/svg/idl/EventInfoBG.jpg";
import BG from "../../assets/svg/idl/herosectionBG.jpg";
import Rout from "../../assets/svg/idl/routmap.svg";

const EventInfoSection = () => {
  const handelRegister = () => {
    const url = "https://rzp.io/rzp/uuxfH4KP";
    window.open(url, "_self", "noopener noreferrer");
  };

  return (
    <div className="EvetnInfoSection" style={{ backgroundImage: `url(${EvetnInfoBG})` }}>
      <div className="EvetnInfocont">
        <h4 className="EvetnInfo_title">Debate Theme</h4>
        <h4 className="EvetnInfo_heading">‘Swarnim Bharat: Virasat aur Vikas’</h4>
        <p className="EvetnInfo_Des">- A balanced narrative emphasizing India&apos;s heritage and development.</p>
        <p className="Eligibility">Eligibility</p>
        <div className="GradeContainers">
          <div className="gradeCont">
            <h6>Juniors</h6> <p>3rd to 5th Grade</p> <div className="glow-box1 box11"></div>
            <div className="glow-box1 box22"></div>
          </div>
          <div className="gradeCont">
            <h6>Middle</h6> <p>6th to 8th Grade</p> <div className="glow-box1 box11"></div>
            <div className="glow-box1 box22"></div>
          </div>
          <div className="gradeCont">
            <h6>Seniors</h6> <p>9th to 12th Grade</p> <div className="glow-box1 box11"></div>
            <div className="glow-box1 box22"></div>
          </div>
        </div>
        <p className="Eligibility">Multi-format participation</p>
        <p className="MultiFormat">
          <span>Dramatic Adaptation:</span> Example: Greta Thunberg discussing education’s role in environmental
          preservation.
          <br />
          <span>Original Oratory:</span> Persuasive speeches on societal concerns.
          <br />
          <span>WSDC Debate:</span> Proposition vs Opposition team-based debates
        </p>
      </div>
      <div
        className="TEventStructure"
        style={{
          backgroundImage: `url(${BG})`
        }}
      >
        <h4>Tournament Structure</h4>
        <div className="routimgcont">
          <img src={Rout} />
        </div>
        <div onClick={handelRegister} className="herocta_cont herocta_cont1">
          <button>Register Now</button>
        </div>
      </div>
    </div>
  );
};

export default EventInfoSection;
