import React, { useState } from "react";
import { useStudentLoginAdmissionIDMutation } from "../../store/auth/auth.api";
import { ROUTES } from "../../constants/routes";
import { useForm } from "react-hook-form";
import { JoinButton } from "./JoinButton";
import { loginFormResolver } from "../../constants/resolvers";
import hideIcon from "../../assets/images/frontside/hide.png";
import showIcon from "../../assets/images/frontside/eye-show.png";
import { studentLoginIdPasswordFormFields } from "../../constants/constants";
let { loginId, password } = studentLoginIdPasswordFormFields;
import { useHistory } from "react-router-dom/";
import { useWebEngageMutation } from "../../store/ApiMutaion";
import { useDispatch } from "react-redux";
import { setModalState } from "../../store/app/app.slice";
import useLeadCapture from "../UseLeadCapture";

export default function LoginPassword({ toggleShowForgotPassword, toggleTAndC, togglePAndP }) {
  const [onStudentLoginIDPassword] = useStudentLoginAdmissionIDMutation();
  const form = useForm({ resolver: loginFormResolver });
  const { handleSubmit, setError, clearErrors } = form;
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(false);
  // const { isSubmitting } = formState;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const history = useHistory();
  const [webEngage] = useWebEngageMutation();
  const dispatch = useDispatch();
  const { handleLeadCapture } = useLeadCapture();
  const handleOTPLogin = () => {
    history.push(ROUTES.OTPLOGIN);
  };
  const handleCreateAcc = () => {
    history.push(ROUTES.STUDENT_CREATE_ACCOUNT);
  };
  const {
    register,
    setValue,
    formState: { errors }
  } = form;

  const showPassToggle = () => setShowPass((prev) => !prev);

  const handleInputKeyPress = (fieldName, value) => {
    value = value.replace(/\s/g, "");
    // setValue(fieldName, value.toUpperCase());

    // validation for minimum length (6 characters)
    if (fieldName === "admission_id") {
      setValue(fieldName, value.toUpperCase());
      if (value.length < 6) {
        loginId = false;
        setError("admission_id", { message: "Login ID must be atleast 6 characters" });
      } else {
        loginId = true;
        clearErrors("admission_id");
      }
    } else if (fieldName === "password") {
      setValue(fieldName, value);
      if (value.length < 6) {
        password = false;
        setError("password", { message: "Password must be atleast 6 characters" });
      } else {
        password = true;
        clearErrors("password");
      }
    }
    const isAllFieldsFilled = loginId && password;
    setIsButtonDisabled(!isAllFieldsFilled);
  };
  const handleOpenModal = () => {
    const opentab = () => {
      const currentUrl = window.location.href;
      window.close();
      window.open(currentUrl, "_blank");
    };
    dispatch(
      setModalState({
        title: "",
        content: (
          <div>
            <p className="banner__contentbox--text mt-2 pt-1">
              Something went wrong, <br />
              please click the button below to <br /> reload the page and try login
            </p>
            <button className="button--membersubmit w-100" onClick={opentab}>
              Reload
            </button>
          </div>
        )
      })
    );
  };

  const handleInputPaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const sanitizedText = pastedText.replace(/\s/g, "");
    event.preventDefault();
    document.execCommand("insertText", false, sanitizedText);
  };

  const onSubmit = async ({ admission_id, password }) => {
    setLoading(true);
    setIsButtonDisabled(true);
    try {
      const rest = { admission_id, password };
      const res = await onStudentLoginIDPassword(rest);
      // Handle the response logic

      if (res?.data?.detail?.includes("User")) {
        setError("admission_id", { message: "Login ID is incorrect or doesn’t exist" });
        setIsButtonDisabled(true);
      } else if (res?.data?.detail?.includes("Incorrect Password")) {
        setError("password", { message: "Login ID & password don’t match" });
        setIsButtonDisabled(true);
      } else if (res?.data?.code !== 200 || res?.error) {
        setError("password", { message: "Oops! Something went wrong. Please try again" });
        handleOpenModal();
        setIsButtonDisabled(false);
      }

      // WebEngage call (if successful login)
      if (res?.data?.code === 200) {
        try {
          await webEngage({
            crn_id: `${res.data.details.user.crn_id}`,
            event_name: "Sign_In_Inmobius",
            event_data: [
              { attribute: "Student Login ID", value: `${res.data.details.user.admission_id}` },
              { attribute: "Phone", value: `${res.data.details.user.phone}` },
              { attribute: "Platform", value: "InMobius" },
              { attribute: "page_url", value: "/login" }
            ]
          });
          await handleLeadCapture(res?.data?.details);
        } catch (error) {
          console.error("Error pushing WebEngage event:", error);
        }
      }
    } catch (error) {
      console.error("Error during login submission:", error);
      setError("password", { message: "Oops! Something went wrong. Please try again" });
    } finally {
      setLoading(false); // Hide loader after response
    }
  };

  function restrictNonAlphaCharacters(event) {
    const input = event.target;
    const regex = /^[a-zA-Z0-9]*$/; // Regular expression to allow only numerical characters

    if (!regex.test(input.value)) {
      // If input doesn't match the regex, remove the last entered character
      input.value = input.value.replace(/[^a-zA-Z0-9]/g, ""); // Remove any non-numeric characters
    }
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-12 position-relative mb-12">
          <label className="tab-lable">Login ID</label>
          <input
            type={"text"}
            className={`member--inputbox pe-3 ${errors.admission_id ? "error--strike" : "input-highlight"}`}
            placeholder="Enter your login ID"
            {...register("admission_id", { required: true, minLength: 6 })}
            onChange={(e) => handleInputKeyPress("admission_id", e.target.value, e)}
            onPaste={handleInputPaste}
            onInput={restrictNonAlphaCharacters}
            required
          />
          {errors.admission_id && <span className="error--strike--text">{errors.admission_id.message}</span>}
        </div>
        <div className="col-12 position-relative mb-12">
          <label className="tab-lable">Password</label>
          <div className="input-group">
            <input
              type={showPass ? "text" : "password"}
              className={`member--inputbox pe-3 ${errors.password ? "error--strike" : "input-highlight"}`}
              placeholder="Enter your Password"
              {...register("password", { required: true, minLength: 6 })}
              onChange={(e) => handleInputKeyPress("password", e.target.value, e)}
              onPaste={handleInputPaste}
              required
            />
            <div className="input-group-append">
              <span className="showhide--pic" onClick={showPassToggle}>
                <img src={!showPass ? hideIcon : showIcon} id="eyeicon" alt="" />
              </span>
            </div>
          </div>
          {errors.password && <span className="error--strike--text">{errors.password.message}</span>}
          <div className="col-12 mb-12 d-flex justify-content-end">
            <span onClick={toggleShowForgotPassword} className="gard--textflow fw-normal forgot-password-link">
              Forgot Password?
            </span>
          </div>{" "}
          <div className="t-c_class">
            By logging in,
            <br /> you agree with our{" "}
            <span onClick={toggleTAndC} className="gard--textflow mx-1">
              T&C
            </span>{" "}
            and{" "}
            <span className="gard--textflow mx-1" onClick={togglePAndP}>
              PP
            </span>
          </div>
        </div>

        <JoinButton
          loader={loading}
          form={form}
          title={"Login"}
          className={"col-12 pt-2 text-center"}
          isButtonDisabled={isButtonDisabled}
        />
        <div className="hr-box">
          <hr className="hr-line" />
          <span>Or</span>
          <hr className="hr-line" />
        </div>
        <div className="col-12 mt-3 mb-3 pb-2 text-center">
          <a className="gard--textflow gard--textflow-mob" style={{ cursor: "pointer" }} onClick={handleOTPLogin}>
            Log in with OTP
          </a>
        </div>
        <div className="col-12 create-ac-link">
          <p className="login--supporttext">
            Don&apos;t have an Inmöbius account?{" "}
            <span className="gard--textflow create-acoount" onClick={handleCreateAcc}>
              create account
            </span>
          </p>
        </div>
      </div>
    </form>
  );
}
