import { CONFIG_VARS } from "../../constants/configuration";

export const InitializeRazorPay = async (
  order,
  fullPaymentOptions,
  userDetails,
  handleSuccessfulPayment,
  handleFaildPayment
) => {
  const finalRazorPayAmount = Math.round((fullPaymentOptions + Number.EPSILON) * 100);
  const razorPayKey = CONFIG_VARS.razorPayKey;
  const options = {
    key: razorPayKey,
    amount: finalRazorPayAmount, // Amount in paise
    currency: "INR",
    name: "Rankguru Technology Solutions Private Limited",
    description: "Infinity Learn",
    image:
      "https://s3.ap-south-1.amazonaws.com/ilwebsite2.devinfinitylearn.in/productmicrocourses/payment/payment_logo.png",
    order_id: order?.gatewayOrderId,
    handler: (response) => {
      handleSuccessfulPayment(response);
    },
    modal: {
      ondismiss: (response) => {
        handleFaildPayment(response);
      }
    },
    prefill: {
      name: `${userDetails?.name} ${userDetails?.lastName}`,
      email: "inmobius@infinitylearn.com",
      contact: userDetails?.phone
    },
    readonly: {
      email: true // This hides the email field in the payment modal
    },

    theme: {
      color: "#3399cc"
    }
  };

  const razorPayWindow = new window.Razorpay(options);
  await razorPayWindow.open();
};

// Usage example
// InitializeRazorPay(order, fullPaymentOptions);
