import React from "react";
import { motion, AnimatePresence } from "framer-motion"; // Import motion and AnimatePresence
import cross from "../../assets/svg/roundcross.svg";
import grade1_8Web from "../../assets/Banners/inmobius_banner_grade_1_8.jpg";
import grade1_8MWeb from "../../assets/Banners/inmobius_mweb_banner_grade_1_8.jpg";
import grade10MWeb from "../../assets/Banners/inmobius_mweb_banner_grade_10.jpg";
import grade10web from "../../assets/Banners/inmobius_banner_grade_10.jpg";
import grade9MWeb from "../../assets/Banners/inmobius_mweb_banner_grade_9.jpg";
import grade9web from "../../assets/Banners/inmobius_banner_grade_9.jpg";
import grade11_12Web from "../../assets/Banners/inmobius_banner_grade_11_12.jpg";
import grade11_12MWeb from "../../assets/Banners/inmobius_mweb_banner_grade_11_12.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getIsAlertOpen } from "../../store/app/selectors";
import { setIsAlertOpen } from "../../store/app/app.slice";
import { getUserInfo } from "../../store/auth/selectors";
import useLeadCapture from "../UseLeadCapture";

const AlertBar = ({ currentPath }) => {
  const isAlertOpen = useSelector(getIsAlertOpen);
  const user = useSelector(getUserInfo);
  const grade = parseInt(user?.grade_id, 10); // Ensure proper base is specified.
  const dispatch = useDispatch();
  const { handelAlerbarLead } = useLeadCapture();
  const handleCloseAlert = () => {
    dispatch(setIsAlertOpen(false));
  };

  const getAlertBanner = () => {
    if (typeof window === "undefined") return null; // Avoid errors in SSR.
    const deviceType = window.innerWidth <= 768 ? "mobile" : "web";

    const banners = {
      "1-8": {
        web: {
          banner: grade1_8Web,
          url: "https://bit.ly/41IXN2k"
        },
        mobile: {
          banner: grade1_8MWeb,
          url: "https://bit.ly/41IXN2k"
        }
      },
      9: {
        web: {
          banner: grade9web,
          url: "https://bit.ly/4gGD7fN"
        },
        mobile: {
          banner: grade9MWeb,
          url: "https://bit.ly/4gGD7fN"
        }
      },
      10: {
        web: {
          banner: grade10web,
          url: "https://bit.ly/4gJtFIG"
        },
        mobile: {
          banner: grade10MWeb,
          url: "https://bit.ly/4gJtFIG"
        }
      },
      "11-12": {
        web: {
          banner: grade11_12Web,
          url: "https://bit.ly/3ZN93Ix"
        },
        mobile: {
          banner: grade11_12MWeb,
          url: "https://bit.ly/3ZN93Ix"
        }
      }
    };

    if (grade >= 1 && grade <= 8) {
      return banners["1-8"][deviceType];
    } else if (grade === 9) {
      return banners["9"][deviceType];
    } else if (grade === 10) {
      return banners["10"][deviceType];
    } else if (grade >= 11 && grade <= 12) {
      return banners["11-12"][deviceType];
    }

    return null; // Fallback if no conditions match.
  };

  const handleDisplay = () => {
    if ((currentPath.includes("/exam-page") || currentPath.includes("reports")) && isAlertOpen) {
      return "block";
    }
    return "none";
  };

  const alertBanner = getAlertBanner();
  const handelLsqCall = () => {
    const params = {
      img_link: `https://inmobiusinfinitylearn.com/static/media/${alertBanner.banner}`,
      redirect_link: alertBanner.url
    };
    handelAlerbarLead(params);
  };
  return (
    <AnimatePresence>
      {isAlertOpen && (
        <motion.div
          style={{ display: handleDisplay() }}
          className="alertBar-cont"
          initial={{ y: 0 }} // Start at the top
          animate={{ y: 0 }} // Keep it at the top when open
          exit={{ y: "-100%" }} // Slide up when closed
          transition={{ type: "spring", stiffness: 300, damping: 30 }}
        >
          <div onClick={handelLsqCall} style={{ position: "relative" }}>
            <img src={cross} onClick={handleCloseAlert} className="alertClose" alt="Close" />
            {alertBanner ? (
              <a href={alertBanner.url} target="_blank" rel="noopener noreferrer">
                <img src={alertBanner.banner} className="alertbarImage" alt="Alertbar" />
              </a>
            ) : (
              <p>No banner available</p>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AlertBar;
