import React from "react";
import group from "../../assets/svg/idl/Group 17.jpg";
import illution from "../../assets/svg/idl/Lines.svg";
import podium from "../../assets/svg/idl/podium.svg";
import lappy from "../../assets/svg/idl/lappy.svg";
import certify from "../../assets/svg/idl/certify.svg";
const WhySection = () => {
  return (
    <div className="whySection">
      <div className="whyTitel">
        Why choose <span>Indian Debating League?</span>
      </div>
      <div className="whydetailSection">
        <div className="whyImgCont">
          <img src={group} alt="group" />
        </div>
        <div className="whydetilsList">
          <div className="whydetilsList_element">
            <div className=" whydetilsList_img">
              <img src={podium} />
            </div>
            <div className="whydetilsList_text">
              <h3>IIT Podium</h3>
              <p>Prestigious podium of IIT Madras for Live Speech & Debate</p>
            </div>
          </div>
          <div className="whydetilsList_element">
            <div className=" whydetilsList_img">
              <img src={lappy} />
            </div>
            <div className="whydetilsList_text">
              <h3>Expert MasterClasses</h3>
              <p>Activity-Based MasterClasses by Internationally Acclaimed Debaters.</p>
            </div>
          </div>
          <div className="whydetilsList_element">
            <div className=" whydetilsList_img">
              <img src={certify} />
            </div>
            <div className="whydetilsList_text">
              <h3>Merit Certificate</h3>
              <p>Recognition across Media Platforms and Certificates for all participants</p>
            </div>
          </div>
        </div>
      </div>

      <div className="glow-box box1"></div>
      <div className="glow-box box2"></div>
      <div className="illutionLine">
        <img src={illution} />
      </div>
    </div>
  );
};

export default WhySection;
