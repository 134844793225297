import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../store/auth/selectors";
import { setModalState } from "../../../../store/app/app.slice";
import { ResetPwdPopup } from "../../../components/modal/ResetPwdPopup";
import { useForgotPasswordMutation } from "../../../../store/auth/auth.api";
import { phoneNumberParserUtils } from "../../../../utils/phoneNumber-parser-utils";
import hideIcon from "../../../../assets/images/frontside/hide.png";
import showIcon from "../../../../assets/images/frontside/eye-show.png";

export const ResetPasswordForm = (props) => {
  const { phoneNumber } = props;
  const dispatch = useDispatch();
  const [forgotPass] = useForgotPasswordMutation();
  const [showPass, setShowPass] = useState(false);
  const [showConfoPass, setShowConfoPass] = useState(false);
  const showPassToggle1 = () => setShowPass((prev) => !prev);
  const showPassToggle2 = () => setShowConfoPass((prev) => !prev);
  const userInfo = useSelector(getUserInfo);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // const handleNewPasswordChange = (e) => {
  //   const value = e.target.value.replace(/\s/g, "");

  //   setNewPassword(value);

  //   if (value.length < 6) {
  //     setNewPasswordError("Password must be at least 6 characters");
  //   } else {
  //     setNewPasswordError("");

  //   }

  //   // You can add additional validation rules here as needed
  // };
  // const handleConfirmPasswordChange = (e) => {
  //   const value = e.target.value.replace(/\s/g, "");

  //   setConfirmPassword(value);

  //   if (value.length < 6) {
  //     setConfirmPasswordError("Password must be at least 6 characters");
  //   } else {
  //     setConfirmPasswordError("");
  //     setIsButtonDisabled(true);
  //   }

  //   // You can add additional validation rules here as needed
  // };

  const onSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("The passwords don't match");
      return;
    }

    const body = {
      isd_code: "91",
      ...phoneNumberParserUtils(phoneNumber),
      new_password: newPassword.toUpperCase()
    };
    console.log("PHONE", userInfo?.phone);
    const res = await forgotPass(body);
    if (res.data !== null) {
      dispatch(setModalState({ content: <ResetPwdPopup />, open: true }));
    } else if (res?.data?.error?.message || res?.error?.data?.detail) {
      setNewPasswordError(res?.data?.error?.message || res?.error?.data?.detail);
    }
  };
  const handlePasswordChange = (e, fieldName) => {
    const value = e.target.value.replace(/\s/g, "");

    if (fieldName === "newPassword") {
      setNewPassword(value);

      if (value.length < 6 || confirmPassword.length < 6) {
        setIsButtonDisabled(true); // Disable the button if either password falls below 6 characters
      } else {
        setIsButtonDisabled(false); // Enable the button otherwise
      }

      setNewPasswordError(value.length < 6 ? "Password must be at least 6 characters" : "");
    } else if (fieldName === "confirmPassword") {
      setConfirmPassword(value);

      if (value.length < 6 || newPassword.length < 6) {
        setIsButtonDisabled(true); // Disable the button if either password falls below 6 characters
      } else {
        setIsButtonDisabled(false); // Enable the button otherwise
      }

      setConfirmPasswordError(value.length < 6 ? "Password must be at least 6 characters" : "");
    }
  };

  return (
    <div className="row">
      <label className="tab-lable">New Password</label>
      <div className="col-12 mb-12">
        {" "}
        <div className="input-group">
          <input
            type={showPass ? "text" : "password"}
            className={`member--inputbox ${newPasswordError ? "error--strike" : ""}`}
            value={newPassword}
            onChange={(e) => handlePasswordChange(e, "newPassword")}
          />
          <div className="input-group-append">
            <span className="showhide--pic" onClick={showPassToggle1}>
              <img src={!showPass ? hideIcon : showIcon} id="eyeicon" alt="" />
            </span>
          </div>
        </div>
        {newPasswordError && <span className="error--strike--text">{newPasswordError}</span>}
      </div>
      <div className="col-12 mb-12">
        <div className="input-group">
          <label className="tab-lable">Confirm Password</label>

          <input
            type={showConfoPass ? "text" : "password"}
            className={`member--inputbox ${confirmPasswordError ? "error--strike" : ""}`}
            value={confirmPassword}
            onChange={(e) => handlePasswordChange(e, "confirmPassword")}
          />
          <div className="input-group-append">
            <span className="showhide--pic2" onClick={showPassToggle2}>
              <img src={!showConfoPass ? hideIcon : showIcon} id="eyeicon" alt="" />
            </span>
          </div>
        </div>
        {confirmPasswordError && <span className="error--strike--text">{confirmPasswordError}</span>}
      </div>
      <div className="col-12 mb-4 pt-2 text-center">
        <button className="button--membersubmit w-100" onClick={onSubmit} disabled={isButtonDisabled}>
          Reset Password
        </button>
      </div>
    </div>
  );
};
