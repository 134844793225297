import { CONFIG_VARS } from "../constants/configuration";
import { useGetSchoolDetailsMutation, useLsqONsuccesPaymentMutation } from "../store/ApiMutaion";
import { getDeviceAndLocationPayload } from "../utils/getDeviceDetails.utils";
import { getUserInfo } from "../store/auth/selectors";
import { useSelector } from "react-redux";
const useLeadCapture = () => {
  const [schoolDetails] = useGetSchoolDetailsMutation();
  const [lsqOnSuccessPayment] = useLsqONsuccesPaymentMutation();
  const actualscore = localStorage.getItem("url");
  const userInfo = useSelector(getUserInfo);
  const date = new Date();
  const formattedDate = date.toISOString().slice(0, 19);
  const handleLoginLeadCaptur = async (data) => {
    try {
      const res = schoolDetails(data).unwrap();
      return res;
    } catch (error) {
      console.error("Error pushing LSQ event:", error);
    }
  };

  const handleLeadCapture = async (data) => {
    if (CONFIG_VARS.environment === "prod") {
      const res = await handleLoginLeadCaptur(data?.user?.school_id);
      const payload = await getDeviceAndLocationPayload();
      const body = {
        ActivityEvent: 213,
        phone: data?.user?.phone,
        ActivityDateTime: formattedDate, //"2025-01-16T06:41:17",
        FirstName: data?.user?.first_name,
        LastName: data?.user?.last_name,
        GradeID: data?.user?.grade_id,
        custom_attribute_fields: {
          mx_Mobile_device: payload?.device_attributes
        },
        LeadFields: {
          mx_Actual_source_URL: "https://inmobiusinfinitylearn.com/",
          mx_Student_Login_ID: data?.user?.admission_id,
          mx_Grade_1: data?.user?.grade_id,
          mx_Target_Exam_1:
            data?.user?.grade_id >= 1 && data?.user?.grade_id <= 5
              ? "Foundation Test"
              : data?.user?.grade_id >= 6 && data?.user?.grade_id <= 8
              ? "Aptitude Test"
              : data?.user?.grade_id >= 9
              ? ""
              : "Invalid Grade",
          mx_Primary_Target_Exam:
            data?.user?.grade_id >= 1 && data?.user?.grade_id <= 5
              ? "Foundation Test"
              : data?.user?.grade_id >= 6 && data?.user?.grade_id <= 8
              ? "Aptitude Test"
              : data?.user?.grade_id >= 9
              ? ""
              : "Invalid Grade", // 1-5 Foundation Test. 6-8 Aptitude Test. 9+ ""
          mx_Inmobius_School_Code: data?.user?.school_id,
          mx_School_Name: res?.data?.school_name,
          mx_Board: res?.data?.board,
          mx_Street1: res?.data?.address,
          mx_Inmobius_School_Address: res?.data?.address,
          mx_City: payload.city === "N/A" ? res?.data?.city : payload.city, // IP city, if null send school city
          mx_Student_City: res?.data?.city,
          mx_School_City: res?.data?.city,
          mx_State: payload.state === "N/A" ? res?.data?.state : payload.state, // IP state, if null send school state
          mx_School_State: res?.data?.state,
          mx_Country: res?.data?.country,
          mx_Zip: res?.data?.pincode ?? payload.pincode, // IP zip, if null send school zip
          mx_School_Pincode: res?.data?.pincode,
          mx_PinCode: res?.data?.pincode,
          mx_Assessment_Date: "", // ""
          mx_CAR_Link: "", // ""
          mx_Certificate: "", // ""
          mx_Fees: res?.data?.fee,
          mx_Tier: res?.data?.tier,
          mx_Category: res?.data?.category,
          mx_CX_Category: res?.data?.category
        },
        ActivityFields: {
          mx_Custom_1: actualscore, // landing page: // user source
          mx_Custom_2: payload?.webType, // medium: Web/MWeb (check with chithra for exact word)
          mx_Custom_3: "InMobius" // product: InMobius
        },
        Source: "InMobius Leads"
      };
      // console.log(body);
      try {
        await lsqOnSuccessPayment(body);
      } catch (error) {
        console.error("Error pushing LSQ event:", error);
      }
    }
  };

  const handelAlerbarLead = async (param) => {
    if (CONFIG_VARS?.environment === "prod") {
      const payload = await getDeviceAndLocationPayload();
      const body = {
        ActivityEvent: 354,
        phone: userInfo?.phone,
        ActivityDateTime: formattedDate,
        FirstName: userInfo?.first_name,
        LastName: userInfo?.last_name,
        GradeID: userInfo?.grade_id,
        ActivityFields: {
          mx_Custom_1: userInfo?.grade_id,
          mx_Custom_2: "InMobius",
          mx_Custom_3: payload?.webType,
          mx_Custom_4: payload.latitude,
          mx_Custom_5: payload?.longitude,
          mx_Custom_6: payload?.ip_address,
          mx_Custom_7: param?.img_link,
          mx_Custom_8: param?.redirect_link,
          mx_Custom_9: userInfo.school_id
        },
        Source: "InMobius Leads"
      };
      try {
        await lsqOnSuccessPayment(body);
      } catch (error) {
        console.error("Error pushing LSQ event:", error);
      }
    }
  };

  return {
    handleLeadCapture,
    handelAlerbarLead
  };
};

export default useLeadCapture;
