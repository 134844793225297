import React from "react";
import IdlTroffy from "../../assets/svg/idl/Idltroffy.svg";
import BG from "../../assets/svg/idl/herosectionBG.jpg";
import threeUsers from "../../assets/svg/idl/3 User.svg";
import clock from "../../assets/svg/idl/clock.svg";
import debat from "../../assets/svg/idl/debate.svg";
import globalwins from "../../assets/svg/idl/globalwins.svg";
const HeroSection = () => {
  const handelRegister = () => {
    const url = "https://rzp.io/rzp/uuxfH4KP";
    window.open(url, "_self", "noopener noreferrer");
  };
  return (
    <div
      style={{
        backgroundImage: `url(${BG})`
      }}
      className="Herosection"
    >
      <div className="Hero_titelcont">
        <div className="Hero_titelSection">
          <h3>
            India’s <span>Largest Debating</span> Student Community
          </h3>
          <p>Motivating Youth Through Debate</p>
        </div>
        <div className="Hero_trofySection">
          <div onClick={handelRegister} className="herocta_cont">
            <button>Register Now</button>
          </div>
          <img src={IdlTroffy} alt="trofy" />{" "}
        </div>
      </div>
      <div className="Hero_achivement">
        <div className="Achivement_title">
          <h3>
            Nurturing India&lsquo;s Best Minds in <span>Speech & Debate</span>
          </h3>
          <p>With our super powers we have reached here</p>
        </div>
        <div className="Achivement_section">
          <div className="Achivement_section1">
            <div className="Achivement_cont">
              <div>
                <img src={threeUsers} />
              </div>
              <div>
                <h6>80,300</h6>
                <p>Debaters Squad</p>
              </div>
            </div>
            <div className="Achivement_cont">
              <div>
                <img src={clock} />
              </div>
              <div>
                <h6>13,700+</h6>
                <p>Training Hours</p>
              </div>
            </div>
          </div>
          <div className="Achivement_section1">
            <div className="Achivement_cont">
              <div>
                <img src={debat} />
              </div>
              <div>
                <h6>123+</h6>
                <p>Debate Tournaments</p>
              </div>
            </div>
            <div className="Achivement_cont">
              <div>
                <img src={globalwins} />
              </div>
              <div>
                <h6>27+</h6>
                <p>Global Wins</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
