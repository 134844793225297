import React, { useState } from "react";
import { Controller } from "react-hook-form";
import IndiaFlag from "../../../assets/svg/flags/India.svg"; // Import the India flag SVG
import UAEFlag from "../../../assets/svg/flags/united-arab-emirates 1.svg"; // Import the UAE flag SVG
//import { phoneNumberParserUtils } from "../../../utils/phoneNumber-parser-utils";

export const PhoneInput = ({
  form,
  disabled,
  name = "phoneNumber",
  handleChange = () => {},
  isEditEnabled,
  handleEditNumberClick,
  isdCode,
  setIsdCode
}) => {
  const {
    control,
    formState: { errors },
    setValue
  } = form;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleISDCodeChange = (code) => {
    setIsdCode(code);
    setIsDropdownOpen(false); // Close dropdown after selection
    setValue("isdCode", code);
    setValue("phoneNumber", "");
    handleEditNumberClick();
  };

  const onChangeHandler = (onChange) => (event) => {
    const phone = event.target.value.toString();
    const result = phone.replace(/[^0-9]/gi, ""); // Clean the phone number
    onChange({ ...event, target: { ...event.target } });
    handleChange(result, isdCode); // Pass the result and isd_code to handleChange
  };

  function restrictNonNumericalCharacters(event) {
    const input = event.target;
    const regex = /^[0-9]*$/; // Regular expression to allow only numerical characters

    if (!regex.test(input.value)) {
      input.value = input.value.replace(/\D/g, ""); // Remove any non-numeric characters
    }
  }

  const handleInputPaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const sanitizedText = pastedText.replace(/\s/g, "");
    event.preventDefault();
    document.execCommand("insertText", false, sanitizedText);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        fontFamily: "montserrat"
      }}
      className="member--inputbox"
    >
      {/* Custom Dropdown for ISD Code */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginRight: "8px",
          position: "relative",
          width: "5rem"
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            padding: "4px 4px",
            backgroundColor: "#141A36",
            borderRadius: "4px",
            color: "#FFF",
            fontFamily: "montserrat",
            fontSize: "14px",
            position: "relative"
          }}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)} // Toggle dropdown
        >
          <img
            style={{ width: "16px", marginRight: "8px" }}
            src={isdCode === "+91" ? IndiaFlag : UAEFlag}
            alt={isdCode === "+91" ? "India Flag" : "UAE Flag"}
          />
          <span>{isdCode}</span>
          <span style={{ marginLeft: "auto" }}>▼</span>
        </div>

        {isDropdownOpen && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              backgroundColor: "#2C2C47",
              color: "#FFF",
              borderRadius: "4px",
              width: "100%",
              zIndex: 10,
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)"
            }}
          >
            <div
              style={{
                padding: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #444"
              }}
              onClick={() => handleISDCodeChange("+91")}
            >
              <img style={{ width: "16px", marginRight: "8px" }} src={IndiaFlag} alt="India Flag" />
              <span>+91</span>
            </div>
            <div
              style={{
                padding: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center"
              }}
              onClick={() => handleISDCodeChange("+971")}
            >
              <img style={{ width: "16px", marginRight: "8px" }} src={UAEFlag} alt="UAE Flag" />
              <span>+971</span>
            </div>
          </div>
        )}
      </div>

      {/* Phone Number Input */}
      <Controller
        control={control}
        name={name}
        render={({ field: { ref, onChange, ...field } }) => (
          <input
            {...field}
            ref={ref}
            type="tel"
            className={`phoneinput ${errors[name] ? "error--strike" : "input-highlight"}`}
            onChange={onChangeHandler(onChange)}
            style={{
              flex: 1,
              border: "none",
              outline: "none",
              backgroundColor: "transparent",
              color: "#FFF",
              fontFamily: "montserrat",
              fontSize: "14px",
              marginLeft: "1rem"
            }}
            disabled={disabled}
            maxLength={isdCode === "+91" ? 10 : isdCode === "+971" ? 9 : 10}
            onInput={restrictNonNumericalCharacters}
            onPaste={handleInputPaste}
            placeholder="Phone Number"
          />
        )}
      />
      {isEditEnabled && (
        <span className="edit_number" onClick={handleEditNumberClick}>
          Edit Number
        </span>
      )}
    </div>
  );
};
