import React from "react";
import whatsapp from "../../../assets/svg/whatsapp-icon.svg";
const WhatsAppButton = () => {
  const handleClick = () => {
    const whatsappUrl = `https://bit.ly/4iIAD2n`;
    window.open(whatsappUrl, "_blank");
  };

  return <img src={whatsapp} onClick={handleClick} alt="Whatsapp" />;
};

export default WhatsAppButton;
