import React, { useEffect, useState } from "react";
import { schoolTypes } from "../../../constants/schoolTypes";
import { useCountry } from "../../../hooks/useCountry";
import { DropdownSelect } from "../../components/DropdownSelect";
import UploadPic from "../../../assets/images/frontend/uploadpic.png";
import { PhoneInputWhite } from "../../components/form/PhoneInputWhite";
import { designationOptions } from "../../../constants/designationValues";
import { useValidatePincodeMutation } from "../../../store/app/country.api";
import { useGetCategoryMutation } from "../../../store/auth/auth.api";

export const StepOne = ({ form, user, updateCategory, category, setValidPin }) => {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    setError,
    clearErrors
  } = form;
  const [validatePincode] = useValidatePincodeMutation();
  const [country, state] = watch(["country", "state", "city"]);
  const { countryList, statesList, citiesList, feeList, tierList } = useCountry({
    country,
    state
  });
  const [disable, setDisable] = useState(true);
  const [selectedFeeId, setSelectedFeeId] = useState(null);
  const [selectedTier, setSelectedTier] = useState(null);
  const [apiValidation, setApiValidation] = useState(false);
  const [selectedCityId, setSelectedCityId] = useState(null);

  const [getCategory] = useGetCategoryMutation();
  useEffect(() => {
    setValue("state", { value: "", isoCode: "" });
  }, [country]);

  useEffect(() => {
    setValue("city", { value: "" });
  }, [state]);
  const handleCitySelect = (e) => {
    setSelectedCityId(e.id);
  };
  const handilPincodeInput = async (e) => {
    const enteredPincode = e.target.value;
    if (enteredPincode.length > 5) {
      const res = await validatePincode({ City_id: selectedCityId, Pincode: enteredPincode });
      if (!res?.data?.valid) {
        setError("pincode", { message: "The pincode doesn’t match the selected state and city" });
        setApiValidation(true);
        setValidPin(false);
      } else {
        clearErrors("pincode");
        setValidPin(true);
      }
    } else {
      setError("pincode", { message: "Pinode must be 6 digits" });
    }
  };
  const handleFeeSelect = async (selectedValue) => {
    setSelectedFeeId(parseInt(selectedValue.feeId));

    if (selectedValue?.feeId && selectedTier) {
      const res = await getCategory({ tier: selectedTier, feeId: selectedValue.feeId });
      updateCategory(res?.data?.category);
    }
    setDisable(false);
  };

  const handleTierSelect = async (selectedValue) => {
    setSelectedTier(selectedValue.value);

    try {
      if (selectedValue?.value && selectedFeeId) {
        const res = await getCategory({ tier: selectedValue.value, feeId: selectedFeeId });
        updateCategory(res?.data?.category);
      } else {
        console.error("Tier or FeeId is missing");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12 mb-3">
        <label htmlFor="" className="form__select--label font--20 font--700">
          School information
        </label>
      </div>
      <div className="col-md-12 mb-3">
        <input type="file" id="fileUpload" hidden />
        <div className="photo__pic">
          <img id="blah" src={UploadPic} alt="photo" />
        </div>
        <div className="text-center">
          <label htmlFor="fileUpload" className="fileupload--label d-inline-block">
            Upload file
          </label>
        </div>
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          School Name<sub>*</sub>
        </label>
        <input type="text" hidden readOnly {...register("school_id")} />
        <input
          type="text"
          className={`form__input--box w-100 ${errors.school_name ? "error--strike" : ""}`}
          {...register("school_name")}
        />
        {errors.school_id && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          School type<sub>*</sub>
        </label>
        <DropdownSelect form={form} name={"school_type"} data={schoolTypes} />
        {errors.school_type?.value && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          School board<sub>*</sub>
        </label>
        <input
          type="text"
          className={`form__input--box w-100 ${errors.board ? "error--strike" : ""}`}
          {...register("board")}
        />
        {errors.board && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Branch name<sub>*</sub>
        </label>
        <input
          type="text"
          className={`form__input--box w-100 ${errors.branch_name ? "error--strike" : ""}`}
          {...register("branch_name")}
        />
        {errors.branch_name && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-12 mb-3">
        <label htmlFor="" className="form__select--label">
          School Address<sub>*</sub>
        </label>
        <textarea
          name=""
          id=""
          rows="3"
          className="form__input--box w-100 resize--none"
          {...register("address")}
        ></textarea>
        {errors.address && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Country<sub>*</sub>
        </label>
        <DropdownSelect form={form} name={"country"} data={countryList} />
        {errors.country?.value && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          State<sub>*</sub>
        </label>
        <DropdownSelect form={form} name={"state"} data={statesList} />
        {errors.state?.value && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          City<sub>*</sub>
        </label>
        <DropdownSelect form={form} onSelect={handleCitySelect} name={"city"} data={citiesList} />
        {errors.city?.value && <span className="error--strike--text">Field is required</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Pincode<sub>*</sub>
        </label>
        <input
          type="number"
          min="0"
          className={`form__input--box w-100 ${errors.pincode ? "error--strike" : ""}`}
          {...register("pincode")}
          onChange={handilPincodeInput}
        />
        {errors.pincode && (
          <span className="error--strike--text"> {apiValidation ? errors.pincode.message : "Field is required"}</span>
        )}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Fee<sub>*</sub>
        </label>
        <DropdownSelect onSelect={handleFeeSelect} form={form} name={"Fee"} data={feeList} />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Tier<sub>*</sub>
        </label>
        <DropdownSelect disabled={disable} onSelect={handleTierSelect} form={form} name={"Tier"} data={tierList} />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Category<sub>*</sub>
        </label>
        <input
          className="form__input--box w-100 "
          {...register("category", { defaultValue: category })}
          value={category}
          placeholder="Category"
        />
      </div>
      <div className="col-md-12 mb-2 pt-4">
        <label htmlFor="" className="form__select--label font--20 font--700">
          Primary Contact Information
        </label>
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact person<sub>*</sub>
        </label>
        <input type="text" className="form__input--box w-100 disabled" disabled value={user?.first_name} />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact number<sub>*</sub>
        </label>
        <input
          type="tel"
          className="form__input--box w-100 disabled"
          disabled
          value={`${user?.isd_code}${user?.phone}`}
        />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact Email<sub>*</sub>
        </label>
        <input type="email" className="form__input--box w-100 disabled" disabled value={`${user?.email}`} />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Designation<sub>*</sub>
        </label>
        <DropdownSelect form={form} {...register("primary_contact_info.designation")} data={designationOptions} />
        {errors.primary_contact_info?.designation && <span className="error--strike--text">Field is required</span>}
      </div>

      <div className="col-md-12 mb-2 pt-4">
        <label htmlFor="" className="form__select--label font--20 font--700">
          Secondary Contact Information
        </label>
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact person
        </label>
        <input
          type="text"
          placeholder="Enter Name"
          {...register("secondary_contact_info.name")}
          className="form__input--box w-100"
        />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact number
        </label>
        <PhoneInputWhite
          className="form__input--box w-100"
          form={form}
          {...register("secondary_contact_info.phone")}
          placeholder={""}
        />
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Contact Email
        </label>
        <input
          type="email"
          placeholder="Enter Email"
          {...register("secondary_contact_info.email", {
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i,
              message: "Invalid email address"
            }
          })}
          className="form__input--box w-100"
        />
        {errors.secondary_contact_info?.email && <span className="error--strike--text">Invalid email</span>}
      </div>
      <div className="col-md-6 mb-3">
        <label htmlFor="" className="form__select--label">
          Designation
        </label>
        <DropdownSelect form={form} {...register("secondary_contact_info.designation")} data={designationOptions} />
      </div>
    </div>
  );
};
