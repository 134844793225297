import * as yup from "yup";
import { regExpValues } from "./schoolTypes";
import { regExDesignation } from "./designationValues";

export const phoneNumberSchema = yup
  .string()
  .test("isd-validation", function (value) {
    const { isdCode } = this.parent; // Access `isdCode` from the parent object
    console.log("Validating:", { value, isdCode });
    if (!value) {
      return this.createError({ message: "Phone number is required" });
    }

    if (isdCode === "+91") {
      if (!/^\d{10}$/.test(value)) {
        return this.createError({
          message: "Phone number must be 10 digits for +91"
        });
      }
    } else if (isdCode === "+971") {
      if (!/^\d{9}$/.test(value)) {
        return this.createError({
          message: "Phone number must be 9 digits for +971"
        });
      }
    }

    return true;
  })
  .required("Phone number is required");

export const emailSchema = yup
  .string()
  .matches(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.(com|org|in|edu)$/i, "Email ID is not valid");
export const schoolNameSchema = yup
  .string()
  .matches(/^[^-\s][a-zA-Z0-9_\s-]+$/, { message: 'Use only "a-z", "A-Z" characters' });
//.matches(/^[A-Za-z]+$/i, { message: 'Use only "a-z", "A-Z" words' });
export const passwordSchema = yup.string().min(5);
export const nameSchema = yup
  .string()
  .max(20, "More than 20 characters are not allowed")
  .matches(/^[a-zA-Z][a-zA-Z0-9_\s-]*$/, { message: 'Use only "a-z", "A-Z" characters' });
export const otpCodeSchema = yup.mixed().nullable();
export const otpVerifiedSchema = yup.boolean();
export const invitationCodeSchema = yup
  .string()
  .matches(/[a-zA-Z0-9]{4}[-][a-zA-Z0-9]{6}[-][a-zA-Z0-9]{6}$/g, { message: "Invalid format code, XXXX-XXXXX-XXXXX" });
export const schoolTypesSchema = yup.string().matches(regExpValues).required();
export const designationSchema = yup.string().matches(regExDesignation).required();
export const gradeNameSchema = yup.string().matches("[1-9]", { message: "Please select grade" });
//export const admissionIDSchema = yup.string().matches(/[0-9]{7}[a-zA-Z0-9]{4}$/g, { message: "Invalid Admission ID" });
export const admissionIDSchema = yup
  .string()
  .matches(/[a-zA-Z0-9]$/g, { message: "Invalid Login ID" })
  .min(6, "Login ID must be atleast 6 characters");
