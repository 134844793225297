import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONFIG_VARS } from "../../constants/configuration";
import { phoneNumberParserUtils } from "../../utils/phoneNumber-parser-utils";

const { baseUrl, phoneBaseUrl, headers, apiVersion } = CONFIG_VARS;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    headers,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth?.tokens?.access_token;
      if (token) {
        headers.set("Authorization", `${token}`);
      }
      headers.set("accept", "application/json");
      headers.set("product-id", "100");
      headers.set("tenant-id", "99999");
      headers.set("Content-Type", "application/json");
      return headers;
    }
  }),
  endpoints: (build) => ({
    signupSchool: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/school-register`,
        method: "POST",
        body: {
          first_name: data.firstName,
          ...(data.lastName != "" ? { last_name: data.lastName } : ""),
          // password: "123",
          primary_contact_info: {
            name: data.firstName,
            ...phoneNumberParserUtils(data.phoneNumber),
            designation: "School Owner",
            ...(data.isdCode ? { isd_code: data.isdCode } : {})
          },
          school_name: data.schoolName,
          email: data.email,
          whatsapp_consent: data.whatsappConsent,
          activation_code: data.invitationCode
        }
      })
    }),
    requestCode: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/request-activation-code`,
        method: "POST",
        body: {
          first_name: data.firstName,
          ...(data.lastName != "" ? { last_name: data.lastName } : ""),
          ...phoneNumberParserUtils(data.phoneNumber),
          school_name: data.schoolName,
          email: data.email,
          whatsapp_consent: data.whatsappConsent
        }
      })
    }),
    signupSchoolFinish: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/school-onboard`,
        method: "POST",
        body: {
          ...data
        }
      })
    }),
    loginSchool: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/school-login`,
        method: "POST",
        body: {
          ...data
        }
      })
    }),
    loginWithOtpSchool: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/school/login-with-otp`,
        method: "POST",
        body: {
          otp: data.otpCode,
          ...phoneNumberParserUtils(data.phoneNumber)
        }
      })
    }),
    validateInvitationCode: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/activation-codes/validate`,
        method: "POST",
        body: {
          code: data
        }
      })
    }),
    verifyPhoneNumber: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/school/verify-phone/${data}`,
        method: "GET"
      })
    }),
    verifyEmail: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/school/verify-email/${data}`,
        method: "GET"
      })
    }),
    getAllSchools: build.query({
      query: () => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/student-grades`,
        params: {}
      })
    }),
    studentSignUp: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/student-register`,
        method: "POST",
        body: {
          first_name: data.firstName,
          ...(data.lastName != "" ? { last_name: data.lastName } : ""),
          // email: data.email,
          whatsapp_consent: data.remember,
          phone: data.phoneNumber,
          grade_id: data.gradeCode,
          school_code: data.invitationCode,
          validate_account: true,
          ...(data.isdCode ? { isd_code: data.isdCode } : {})
        }
      })
    }),

    studentLoginAdmissionID: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/student-login`,
        method: "POST",
        body: {
          password: data.password.toUpperCase(),
          admission_id: data.admission_id.toUpperCase()
        }
      })
    }),
    studentLoginPassword: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/student-login`,
        method: "POST",
        body: {
          password: data.password,
          ...phoneNumberParserUtils(data.phoneNumber)
        }
      })
    }),
    studentLoginOTP: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/student-login-otp`,
        method: "POST",
        body: {
          otp: data.otpCode,
          isd_code: data.isdCode,

          ...phoneNumberParserUtils(data.phoneNumber)
        }
      })
    }),
    validatePhone: build.mutation({
      query: (data) => ({
        url: `${phoneBaseUrl}/${apiVersion}/auth/validate-phone/user`,
        method: "POST",
        body: {
          phone: data.phone,
          isd_code: data.isdCode
        }
      })
    }),
    studentSubmitQuestions: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/questionnaires/register-student-response`,
        method: "POST",
        body: data
      })
    }),

    getEvents: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/insights/events`,
        method: "POST",
        body: data
      })
    }),

    getToken: build.mutation({
      query: (data) => ({
        url:
          `${baseUrl["1"]}/${apiVersion}/wizklub/generate-token?` +
          "username=" +
          data.username +
          "&name=" +
          data.name +
          "&grade=" +
          data.grade +
          "&event_name=" +
          data.event_name,
        method: "POST"
        //query: { data }
      })
    }),
    getCategory: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/Fee-category/category?tier=${data.tier}&feeid=${data.feeId}`,
        method: "GET"
      }),
      headers: async ({ getState }) => {
        const token = getState().auth?.tokens?.access_token;
        if (token) {
          return {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json"
          };
        }
        return {
          accept: "application/json",
          "Content-Type": "application/json"
        };
      }
    }),
    getFeeList: build.mutation({
      query: () => ({
        url: `${baseUrl["1"]}/${apiVersion}/Fee-category/feelist`,
        method: "GET"
      }),
      headers: async ({ getState }) => {
        const token = getState().auth?.tokens?.access_token;
        if (token) {
          return {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json"
          };
        }
        return {
          accept: "application/json",
          "Content-Type": "application/json"
        };
      }
    }),
    getTierList: build.mutation({
      query: () => ({
        url: `${baseUrl["1"]}/${apiVersion}/Fee-category/tierlist`,
        method: "GET"
      }),
      headers: async ({ getState }) => {
        const token = getState().auth?.tokens?.access_token;
        if (token) {
          return {
            Authorization: `Bearer ${token}`,
            accept: "application/json",
            "Content-Type": "application/json"
          };
        }
        return {
          accept: "application/json",
          "Content-Type": "application/json"
        };
      }
    }),
    getUAMToken: build.mutation({
      query: (data) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/get-uam-token` + data.query,
        method: "GET"
      })
    }),
    resetPassword: build.mutation({
      query: (body) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/reset-password/user`,
        method: "POST",
        body: body
      })
    }),
    forgotPassword: build.mutation({
      query: (body) => ({
        url: `${baseUrl["1"]}/${apiVersion}/onboarding/forget-password/user`,
        method: "POST",
        body: body
      })
    })
  })
});

export const {
  useSignupSchoolMutation,
  useRequestCodeMutation,
  useSignupSchoolFinishMutation,
  useLoginSchoolMutation,
  useLoginWithOtpSchoolMutation,
  useValidateInvitationCodeMutation,
  useVerifyPhoneNumberMutation,
  useGetAllSchoolsQuery,
  useStudentSignUpMutation,
  useStudentLoginPasswordMutation,
  useValidatePhoneMutation,
  useVerifyEmailMutation,
  useStudentLoginOTPMutation,
  useStudentLoginAdmissionIDMutation,
  useStudentSubmitQuestionsMutation,

  useGetEventsMutation,
  useGetTokenMutation,
  useGetUAMTokenMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useGetFeeListMutation,
  useGetCategoryMutation,
  useGetTierListMutation
} = authApi;
