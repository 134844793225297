import React, { useState } from "react";
import { motion } from "framer-motion";
import CallButton from "./CallService";
import WhatsAppButton from "./whatappService";
import { useSelector } from "react-redux";
import { getSelectedEventsDetails } from "../../../store/auth/selectors";
import call from "../../../assets/svg/greencall.svg";

const ServiceIndex = ({ currentPath }) => {
  const [isOpen, setIsOpen] = useState(false);
  const examDetails = useSelector(getSelectedEventsDetails);
  const examStatus = examDetails?.exam_attempted;

  const toggleButtons = () => {
    setIsOpen(!isOpen);
  };

  const getPosition = (index, total) => {
    const angle = (Math.PI / 2) * (index / (total - 1));
    const radius = 70;
    const x = -radius * Math.cos(angle);
    const y = -radius * Math.sin(angle);
    return { x, y };
  };

  // Render service buttons only on /exam-page and /reports
  const showService = currentPath.includes("/exam-page") || currentPath.includes("reports");

  // Determine if the call button should be shown
  const shouldShowCallButton = !(currentPath.includes("/exam-page") && examStatus === false);

  if (!showService) {
    return null; // Don't render anything if not on the desired pages
  }

  return (
    <div className="serviceIndex">
      <motion.div
        className="fab"
        onClick={toggleButtons}
        animate={{ rotate: isOpen ? 45 : 0 }}
        transition={{ type: "spring", stiffness: 300 }}
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          borderRadius: "50%",
          width: "50px",
          height: "50px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
      >
        <img src={call} alt="call" />
      </motion.div>

      {isOpen && (
        <motion.div
          className="whatsappButton"
          initial={{ opacity: 0, x: 0, y: 0 }}
          animate={{
            opacity: 1,
            x: getPosition(0, 2).x,
            y: getPosition(0, 2).y
          }}
          transition={{ type: "spring", stiffness: 300 }}
          style={{
            position: "absolute",
            bottom: "90px",
            right: "20px",
            backgroundColor: "#25d366",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          <WhatsAppButton />
        </motion.div>
      )}

      {isOpen && shouldShowCallButton && (
        <motion.div
          className="callButton"
          initial={{ opacity: 0, x: 0, y: 0 }}
          animate={{
            opacity: 1,
            x: getPosition(1, 2).x,
            y: getPosition(1, 2).y
          }}
          transition={{ type: "spring", stiffness: 300 }}
          style={{
            position: "absolute",
            bottom: "90px",
            right: "20px",
            backgroundColor: "#34b7f1",
            border: "3px solid #FFF",
            borderRadius: "50%",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer"
          }}
        >
          <CallButton />
        </motion.div>
      )}
    </div>
  );
};

export default ServiceIndex;
