import React from "react";
import { CrossIcon } from "../../../assets/svg";

export const ReloginPopUp = () => {
  return (
    <div className="modal-wrapper">
      <div className="modal-container1">
        <a className="modal-close" href="/">
          <CrossIcon width={20} height={20} />
        </a>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "1rem"
          }}
        ></div>
        <div className="banner__contentbox--text-popup mt-2 pt-1">
          Your session has expired. <br />
          Please log in again.
          <br />
          <br />
          <br />
          <div className="mb-4">
            <a href="/login" className="back_login">
              <span>Back To Login</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
